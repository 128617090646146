#rev_slider_37_1_wrapper .custom.tparrows {
	cursor: pointer;
	background: #000;
	background: rgba(0, 0, 0, 0.5);
	width: 40px;
	height: 40px;
	position: absolute;
	display: block;
	z-index: 1000
}

#rev_slider_37_1_wrapper .custom.tparrows.rs-touchhover {
	background: #000
}

#rev_slider_37_1_wrapper .custom.tparrows:before {
	font-family: 'revicons';
	font-size: 18px;
	color: #fff;
	display: block;
	line-height: 40px;
	text-align: center
}

#rev_slider_37_1_wrapper .custom.tparrows.tp-leftarrow:before {
	content: '\e824'
}

#rev_slider_37_1_wrapper .custom.tparrows.tp-rightarrow:before {
	content: '\e825'
}

#rev_slider_37_1_wrapper .hermes .tp-bullet {
	overflow: hidden;
	/* border-radius: 50%; */
	width: 50px;
	height: 5px;
	background-color: rgba(255, 255, 255, 0.5);
	/* box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.75); */
	-webkit-transition: background 0.3s ease;
	transition: background 0.3s ease;
	position: absolute
}

#rev_slider_37_1_wrapper .hermes .tp-bullet.rs-touchhover {
	background-color: rgba(173, 23, 43, 1)
}

#rev_slider_37_1_wrapper .hermes .tp-bullet:after {
	content: ' ';
	position: absolute;
	bottom: 0;
	height: 0;
	left: 0;
	width: 100%;
	background-color: rgba(255, 255, 255, 1);
	/* box-shadow: 0 0 1px rgba(255, 255, 255, 0.75); */
	-webkit-transition: height 0.3s ease;
	transition: height 0.3s ease
}

#rev_slider_37_1_wrapper .hermes .tp-bullet.selected:after {
	height: 100%
}

#rev_slider_37_1_wrapper rs-progress {
margin-left: -50%;
}

#product-browser {
	border-bottom: none;
}

#product-browser .nav-link {
	background: none;
	border: 1px solid rgba(255, 255, 255, 0);
	border-bottom: 3px solid white;
	padding: 12px 24px;
	transition: color 0.3s ease-out;
}

#product-browser .nav-link:hover {
	color: white !important;
}

#product-browser .nav-link.active, #product-browser .nav-link:is(:focus, :active, :focus-visible, :target) {
	border: 1px solid rgba(255, 255, 255, 0);
	transition: none;
}

#product-browser .nav-link.active {
	border: none;
	border-bottom: 3px solid #ad172b;
	font-weight: 400 !important;
	color: white !important;
}

#product-cards .card {
	box-shadow: inset 0px 0px 50px 10px rgba(0, 0, 0, 0.1);
}
#product-cards .card .card-footer {
	border: none;
	background: transparent;
}

#vocations-list button {
	padding-left: 20px;
	border-color: #ccc;
	transition: all 0.3s ease-out;
}

#vocations-list button:hover {
	/* padding-left: 25px; */
	color: black;
	background: none;
}

#vocations-list button::before {
	display: inline-block;
	position: absolute;
	top: 50%;
	left: -10px;
	margin-top: -20px;
	font-family: bootstrap-icons !important;
	content: "\F284";
	font-size: 34px;
	color: transparent;
	transition: all 0.3s ease-out;
}

#vocations-list button:focus {
	background: none;
	color: #ad172b;
}

#vocations-list button:focus::before, #vocations-list button.active::before {
	left: -20px;
	color: #ad172b;
}

#vocations-list button.active {
	background: none;
	border-color: #ccc;
	color: #ad172b;
}

#vocations-list button.active:hover {
	padding-left: 20px;
}
#vocations-slider .slick-track {height: 100%;}

#vocations-slider .slide {
	min-height: 600px;
}
.hl-summary {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}
.voc-background {
	background-size: cover;
	background-position: center 10%;
}
.voc-summary, .hl-summary {background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);}
.voc-summary hr {border-color: rgba(255, 255, 255, 0.3);}